/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import Plaatjie from '@ubo/plaatjie'
import ButtonDefault from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'
import Search from 'components/Check/Search'

// Media
import BottomLeft from 'img/background/bottom-left.inline.svg'

const Banner = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`

const BannerComp = css`
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  @media (min-width: 1440px) {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
  }
`

const BannerImage = styled(Plaatjie)`
  ${BannerComp};
  z-index: 1;

  & img {
    opacity: 1;
  }
`

const BannerOverlay = styled.div`
  ${BannerComp};
  z-index: 2;
  background: linear-gradient(
    270deg,
    rgba(114, 57, 149, 0.2) 0%,
    rgba(11, 189, 209, 0.8) 100%
  );
`

const BannerContent = styled.div`
  position: relative;
  z-index: 3;

  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.l};
    color: ${({ theme }) => theme.color.text.light};

    & span {
      color: ${({ theme }) => theme.color.text.secondary} !important;
    }
  }
`

const BannerContentMap = styled.div`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 25px;
  overflow: hidden;

  @media (min-width: 992px) {
    border: 8px solid ${({ theme }) => theme.color.text.secondary};
    height: 450px;
    width: 450px;
  }

  @media (max-width: 991px) {
    border: 5px solid ${({ theme }) => theme.color.text.secondary};
    height: 400px;
    width: 400px;
  }

  @media (min-width: 768px) {
    position: absolute;
    bottom: -150px;
    right: 0.75rem;
  }

  @media (max-width: 767px) {
    height: 300px;
    width: 300px;
  }
`

const TerrainsContent = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const Terrains = styled.div`
  border-radius: 25px;

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const WhyImage = styled(Plaatjie)`
  @media (min-width: 768px) {
    width: 550px;
    height: 350px;
    position: absolute !important;
    right: -75px;
    top: -25px;
  }
`

const WhyContent = styled.div`
  background-color: ${({ theme }) => theme.color.face.main};
  position: relative;

  & h2,
  & p,
  & a {
    color: ${({ theme }) => theme.color.text.light};
  }

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }

  @media (min-width: 768px) {
    &:after {
      background: ${(props) => props.theme.color.face.main};
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 2000px;
    }
  }
`

const QuestionsContent = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const FormBackground = styled(BottomLeft)`
  position: absolute;
  z-index: 1;
  left: -150px;
  bottom: 15px;
  width: 600px;
  height: 600px;

  & g {
    opacity: 1;
  }

  & path {
    fill: #517f92;
  }
`

const FormContent = styled(ParseContent)`
  & h2,
  & p,
  & a {
    color: ${({ theme }) => theme.color.text.light};
  }

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const Form = styled.div`
  border-radius: 15px;
  position: relative;
  z-index: 2;
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast },
    bannerImg,
    whyImg,
  },
}) => {
  const [isCoresOpen, setIsCoresOpen] = React.useState(false)
  const [isTerrainsOpen, setIsTerrainsOpen] = React.useState(false)
  const [isPostalsOpen, setIsPostalsOpen] = React.useState(31)

  let terrains = []
  let cores = []
  let postals = []

  if (acf.terrains) {
    terrains = acf.terrains
      .split('~')
      .map((terrain) => terrain.replace(/\s*$/, ''))
  }

  if (acf.cores) {
    cores = acf.cores.split('~').map((core) => core.replace(/\s*$/, ''))
  }

  if (acf.postals) {
    postals = acf.postals.split('~').map((postal) => postal.replace(/\s*$/, ''))
  }

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let distinctPostals = []

  postals.forEach((string) => {
    for (let i = 0; i < alphabet.length; i++) {
      for (let j = 0; j < alphabet.length; j++) {
        const newString = string + alphabet[i] + alphabet[j]
        distinctPostals.push(newString)
      }
    }
  })

  distinctPostals = distinctPostals.sort((a, b) =>
    a.slice(-2).localeCompare(b.slice(-2))
  ) // Sort newArray based on last two characters

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url: bannerImg.childImageSharp.gatsbyImageData.images.fallback.src,
        }}
      />

      <Banner>
        {acf.banner_image ? (
          <BannerImage
            image={acf.banner_image}
            alt={acf.name}
            objectFit="cover"
            loading="eager"
          />
        ) : (
          <BannerImage
            image={{ localFile: bannerImg }}
            alt={acf.name}
            objectFit="cover"
            loading="eager"
          />
        )}
        <BannerOverlay />
        <BannerContent>
          <div className="container py-5">
            <div className="row py-md-5">
              <div className="col-md-6 py-lg-5">
                <ParseContent content={acf.banner_description} />
              </div>
              <div className="col-md-6 mt-5 mt-md-0 position-relative d-flex justify-content-center">
                <BannerContentMap>
                  <GoogleMaps region={acf.name} />
                </BannerContentMap>
              </div>
            </div>
          </div>
        </BannerContent>
      </Banner>

      <section>
        <div className="container py-5 mt-md-5">
          <div className="row">
            <div className="col-lg-7 col-md-6 pr-5">
              <TerrainsContent
                className="pr-lg-5"
                content={acf.loyal_description}
              />
            </div>
            <div className="col-lg-5 col-md-6 mt-4 mt-md-0">
              {terrains && terrains.length > 0 && (
                <Terrains className="color-background-secondary p-4 p-md-5">
                  <h2>
                    Bedrijventerreinen in{' '}
                    {acf.name.replace('Zakelijk internet', '')}
                  </h2>
                  <div className="mt-4">
                    <ul>
                      {[...new Set(terrains)]
                        .filter((_, index) =>
                          isTerrainsOpen ? index >= 0 : index < 10
                        )
                        .map((terrain) => {
                          const parsed = terrain
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')

                          let terrainLink = parsed.replaceAll(
                            /[-'`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi,
                            '-'
                          )

                          if (
                            terrainLink.charAt(terrainLink.length - 1) === '-'
                          ) {
                            terrainLink = terrainLink.slice(0, -1)
                          }

                          const customLink = terrainLink
                            .toLowerCase()
                            .replaceAll(/\s+/g, '-')

                          const finalLink = customLink.replace(/-{2,}/g, '-')

                          return (
                            <li key={terrain}>
                              <a
                                href={`${path}${finalLink
                                  .toLowerCase()
                                  .replaceAll(/\s+/g, '-')}/`}
                                className="font-size-m color-secondary"
                              >
                                {terrain}
                              </a>
                            </li>
                          )
                        })}
                    </ul>
                    {terrains && terrains.length > 10 && (
                      <div className="mt-5 text-center">
                        <ButtonDefault isCustom>
                          <button
                            type="button"
                            onClick={() => {
                              setIsTerrainsOpen(!isTerrainsOpen)
                            }}
                          >
                            {!isTerrainsOpen ? 'Meer' : 'Minder'} tonen
                          </button>
                        </ButtonDefault>
                      </div>
                    )}
                  </div>
                </Terrains>
              )}
              {cores && cores.length > 0 && (
                <Terrains className="color-background-secondary p-4 p-md-5 mt-5">
                  <h2>Kernen in {acf.name.replace('Zakelijk internet', '')}</h2>
                  <div className="mt-4">
                    <ul>
                      {[...new Set(cores)]
                        .filter((_, index) =>
                          isCoresOpen ? index >= 0 : index < 10
                        )
                        .map((core) => {
                          return (
                            <li key={core}>
                              <span className="font-size-s">{core}</span>
                            </li>
                          )
                        })}
                    </ul>
                    {cores && cores.length > 10 && (
                      <div className="mt-5 text-center">
                        <ButtonDefault isCustom>
                          <button
                            type="button"
                            onClick={() => {
                              setIsCoresOpen(!isCoresOpen)
                            }}
                          >
                            {!isCoresOpen ? 'Meer' : 'Minder'} tonen
                          </button>
                        </ButtonDefault>
                      </div>
                    )}
                  </div>
                </Terrains>
              )}
              {distinctPostals && distinctPostals.length > 0 && (
                <Terrains className="color-background-secondary p-4 p-md-5 mt-5">
                  <h2>
                    Postcodegebieden in{' '}
                    {acf.name.replace('Zakelijk internet', '')}
                  </h2>
                  <div className="mt-4">
                    <div className="flex flex-wrap relative">
                      {[...new Set(distinctPostals)].map((postal, index) => {
                        return (
                          <p
                            key={postal}
                            className="font-size-s d-inline"
                            style={
                              index < isPostalsOpen
                                ? { position: 'relative' }
                                : {
                                    position: 'absolute',
                                    top: 0,
                                    left: '100%',
                                    display: 'none',
                                    opacity: 0,
                                  }
                            }
                          >
                            <>{postal}, </>
                          </p>
                        )
                      })}
                    </div>
                    <div
                      className={`d-flex ${
                        isPostalsOpen > 31
                          ? 'justify-content-between'
                          : 'justify-content-center'
                      }`}
                    >
                      {distinctPostals &&
                        isPostalsOpen < distinctPostals.length && (
                          <div className="mt-5 text-center">
                            <ButtonDefault isCustom>
                              <button
                                type="button"
                                onClick={() => {
                                  setIsPostalsOpen(
                                    (prevState) => prevState + 31
                                  )
                                }}
                              >
                                Meer tonen
                              </button>
                            </ButtonDefault>
                          </div>
                        )}
                      {distinctPostals && isPostalsOpen > 31 && (
                        <div className="mt-5 text-center">
                          <ButtonDefault isCustom>
                            <button
                              type="button"
                              className="p-2"
                              onClick={() => {
                                setIsPostalsOpen(31)
                              }}
                            >
                              <svg
                                width="18"
                                height="18"
                                version="1.1"
                                viewBox="0 0 700 700"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <path
                                  fill="#FFF"
                                  d="m350 14-34.82 34.816-117.4 117.41 34.816 34.816 92.781-92.785v437.75h49.242v-437.74l92.781 92.781 34.816-34.816z"
                                />
                              </svg>
                            </button>
                          </ButtonDefault>
                        </div>
                      )}
                    </div>
                  </div>
                </Terrains>
              )}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-5">
            {acf.banner_description.includes('Offerte aanvragen') ? (
              <div className="col-md-12 mx-auto">
                <Search />
              </div>
            ) : (
              <div className="col-md-10 mx-auto">
                <ParseContent
                  content={`
                  <h2><strong>Check de status van Glasvezel in ${acf.name.replace(
                    'Zakelijk internet',
                    ''
                  )}</strong></h2>
                  <p>Check de glasvezelstatus en schrijf u in voor de glasvezelwekker. Zo blijft u altijd op de hoogte van alle ontwikkelingen omtrent glasvezel.</p>
                `}
                />
                <div className="mt-5">
                  <ButtonDefault isCustom external isAnchor>
                    <a
                      target="_blank"
                      rel="norefferer"
                      href="https://opglas.nl/postcodecheck/"
                    >
                      Doe de postcodecheck
                    </a>
                  </ButtonDefault>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5 mt-lg-5">
          <div className="row">
            <div
              className="col-lg-5 col-md-4 position-relative"
              style={{ zIndex: 2 }}
            >
              <WhyImage
                loading="eager"
                key={{ localFile: whyImg }}
                image={{ localFile: whyImg }}
                alt={acf.name}
                objectFit="cover"
              />
            </div>
            <div
              className="col-lg-7 col-md-8 positino-relative"
              style={{ zIndex: 1 }}
            >
              <WhyContent className="pl-md-5 py-md-4">
                <ParseContent
                  className="p-md-5 p-4 ml-md-4"
                  content={acf.why_description}
                />
              </WhyContent>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5 mt-lg-5">
          <div className="row">
            <div className="col-lg-4 col-sm-5">
              <Terrains className="color-background-secondary p-4 p-lg-5">
                <h2>Vragen? Neem contact op!</h2>
                <div className="mt-4">
                  <ButtonDefault
                    isAnchor
                    className="w-100 text-center"
                    to="tel:085 48 59 380?"
                  >
                    Bel ons 085 48 59 380?
                  </ButtonDefault>

                  <ButtonDefault
                    isAnchor
                    className="w-100 mt-3 text-center"
                    to="mailto:info@clearmind.nu"
                  >
                    Mail ons
                  </ButtonDefault>
                </div>
              </Terrains>
            </div>
            <div className="col-lg-8 col-sm-7 pl-lg-5 mt-4 mt-sm-0">
              <QuestionsContent
                className="pl-md-5"
                content={acf.superfast_description}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="color-background-main" id="offerte">
        <div className="container py-5 position-relative">
          <FormBackground />
          <div className="row justify-content-center py-lg-5">
            <div className="col-lg-7 col-sm-10">
              <FormContent
                content={`
                  <h2 class="mb-4" style="text-align: center;">Vraag nu vrijblijvend een offerte aan!</h2>
                `}
              />
              <FormContent
                className="text-white text-center"
                content={`
                  <p>
                  Heeft u nog specifieke vragen? Wij beantwoorden ze uiteraard graag. Neem contact met ons op en u hoort snel van ons. We zijn telefonisch, via de mail en chat bereikbaar. Of misschien bent u benieuwd voor welke prijs u kunt kiezen voor zakelijk glasvezel internet van Clear Mind? Vraag dan nu vrijblijvend <a href="https://www.clearmind.nl/offerte-aanvragen/">een offerte aan</a> door het onderstaande formulier in te vullen. 
                  </p>
                `}
              />

              <div className="mt-5">
                <Form className="color-background-secondary p-4">
                  <GravityForm
                    id={9}
                    defaultValues={{
                      input_5: acf.name,
                    }}
                    split={false}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <ParseContent
            content={`
              <h2 style="text-align: center; font-weight: 600;">In 5 stappen naar razend<span style="color: #723995;">snel</span> en <span style="color: #723995;">stabiel</span> internet!</h2>
            `}
          />

          <div className="mt-5">
            <Steps />
          </div>
        </div>
      </section>
    </Layout>
  )
}

const googleApiKey = 'AIzaSyBl_xlo5FoGFzF8dfIxb29G-bpWVEoOB6o'

const GoogleMaps = ({ region = '' }) => {
  const query = region
    .replace('Glasvezel ', '')
    .replace('Zakelijk internet ', '')

  return (
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${query}&language=nl-NL&maptype=roadmap&region=nl`}
    />
  )
}

const items = [
  {
    number: 1,
    description: `
      <h3>Stap 1: <span class="color-contrast">Doe de postcodecheck</span></h3>
      <p>Vul uw postcode, huisnummer in en check de mogelijkheden op uw adres.</p>
    `,
  },
  {
    number: 2,
    description: `
      <h3>Stap 2: <span class="color-contrast">Kies uw bedrijf en start de offerteaanvraag</span></h3>
      <p>Selecteer op uw adres uw bedrijf en bekijk welke verbindingen er voor u beschikbaar zijn.</p>
    `,
  },
  {
    number: 3,
    description: `
      <h3>Stap 3: <span class="color-contrast">Kies de verbinding die bij u past</span></h3>
      <p>Selecteer de contractduur, snelheid, service en leverdatum van de door uw gewenste verbinding. Kies vervolgens, indien gewenst, ook uit verschillende aanvullende opties en verstuur uw persoonlijke aanvraag.</p>
    `,
  },
  {
    number: 4,
    description: `
      <h3>Stap 4: <span class="color-contrast">Digitaal ondertekenen van de offerte</span></h3>
      <p>-	U ontvangt direct een passende offerte op de mail. Akkoord? Ondertekenen doet u eenvoudig, snel en geheel online via iDIN. Identificeer uzelf door in te loggen bij uw bank, onderteken de offerte digitaal en geef een incassomachtiging af.</p>
    `,
  },
  {
    number: 5,
    description: `
      <h3>Stap 5: <span class="color-contrast">Supersnel zakelijk internet</span></h3>
      <p>Op de gewenste leverdatum komt één van onze engineers bij u langs om uw nieuwe zakelijke verbinding te installeren. Binnen no-time ben jij weer up & running en profiteer ook jij van supersnel zakelijk internet!</p>
    `,
  },
]

const StepNumber = styled.button`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.color.text.main};
  font-weight: ${({ theme }) => theme.font.weight.xl};
  color: ${({ theme }) => theme.color.text.main};
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0.15)};

  &:hover {
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.xxxl};
    height: 125px;
    width: 125px;
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.xxl};
    height: 100px;
    width: 100px;
  }

  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.font.size.xl};
    height: 75px;
    width: 75px;
  }
`

const StepContent = styled(motion.div)`
  overflow: hidden;
`

const StepContentBlock = styled(ParseContent)`
  border-radius: 15px;
`

const Steps = () => {
  const [current, setCurrent] = React.useState(1)

  return (
    <>
      <div className="row">
        {items.map((item, index) => (
          <div key={index} className="col">
            <StepNumber
              type="button"
              active={item.number === current}
              onClick={() => {
                if (item.number === current) {
                  setCurrent(null)
                } else {
                  setCurrent(item.number)
                }
              }}
            >
              {item.number}
            </StepNumber>
          </div>
        ))}
      </div>

      <AnimatePresence>
        {current !== null && (
          <StepContent
            key={current}
            exit={{
              height: 0,
            }}
            initial={{
              height: 0,
            }}
            animate={{
              height: 'auto',
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-8">
                <StepContentBlock
                  className="mt-5 p-3 color-background-secondary"
                  content={
                    items.find(({ number }) => number === current).description
                  }
                />
              </div>
            </div>
          </StepContent>
        )}
      </AnimatePresence>
    </>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressWpGemeentes(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner_description
        loyal_description
        name
        superfast_description
        terrains
        cores
        postals
        why_description
      }

      yoast_meta {
        name
        content
        property
      }
    }

    bannerImg: file(relativePath: { eq: "region-banner-holder.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }

    whyImg: file(relativePath: { eq: "region-why-holder.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

export default PageTemplate
